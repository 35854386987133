import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaAddressBook, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import MasterLayout from "../layouts/master";


const ContactUs = (props) => {
  return (
    <MasterLayout>
      <Container maxW={"6xl"}>
        <Heading
          lineHeight={1.1}
          fontWeight={"bold"}
          py={6}
          fontSize={{ base: "xl", sm: "xl", lg: "6xl" }}
        >
          <Text
            as={"span"}
            position={"relative"}
            _after={{
              content: "''",
              width: "full",
              height: "30%",
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "#8fe7a3",
              zIndex: -1,
            }}
          >
            Contact Us
          </Text>
        </Heading>
        <Box>
          <Text fontSize={"1.2rem"} fontStyle={"italic"}>
            Agar humm phone na uthaye to gussa matt hona please!, we're small
            team right now 🙇
          </Text>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            display={"flex"}
            alignItems={"center"}
            p={12}
            my={18}
          >
            <Flex>
              <SimpleGrid spacingY={8} spacingX={12} columns={1}>
                <HStack spacing={4}>
                  <FaPhoneAlt color="#52bb6a" size={"1.4rem"} />
                  <Text fontWeight={"bold"} fontSize={"1rem"}>
                    +91 9799166556 (call between 8AM - 6PM)
                  </Text>
                </HStack>
                <HStack spacing={4}>
                  <MdEmail color="#52bb6a" size={"1.4rem"} />
                  <Text fontWeight={"bold"} fontSize={"1rem"}>
                    info@sunokitaab.com
                  </Text>
                </HStack>
                <HStack spacing={4}>
                  <FaAddressBook color="#52bb6a" size={"1.4rem"} />
                  <Text fontWeight={"bold"} fontSize={"1rem"}>
                    307, Supreme Cowork DLF Mall, Shalimar Bagh, Delhi, 110088,
                    India
                  </Text>
                </HStack>
              </SimpleGrid>
            </Flex>
          </Box>
        </Box>
      </Container>
    </MasterLayout>
  );
};

export default ContactUs